import type { EScriptData } from './types'
import { EScriptDataRunType } from './types'
import { generateEScript20230411Link } from './v2023-04-11'

export { EScriptData, EScriptDataRunType }

export const generateEScriptLink = (data: EScriptData): string =>
  // all valid versions default to 2023-04-11, but discrimination can be
  // implemented here when new versions are added
  generateEScript20230411Link(data)
