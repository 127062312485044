import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useMemo } from 'react'

import { openPrescriptionProductModal } from '../../../lib/prescriptions/builder/product-modal'
import { AutoShipOption } from '../../../lib/prescriptions/builder/types'
import Link from '../../global/Link'
import * as Styled from './styled'

import productImagePlaceholder from '../../../img/product-image-placeholder.png'

export type PrescriptionBuilderListableProduct = {
  sku: string
  quantity: number
  autoShip: AutoShipOption
  urlKey: string
  image: string
  name: string
}

const formatAutoShip = (autoShip: 1 | 2 | 3 | null) =>
  !autoShip
    ? 'Single Order'
    : `Auto-Ship: Ships every ${autoShip === 1 ? 'month' : `${autoShip} months`}`

export const ProductListEntry: React.FC<{
  product: PrescriptionBuilderListableProduct
  onRemoveItem: () => void
}> = ({ product, onRemoveItem }) => {
  const quantity = useMemo(() => product.quantity, [product.quantity])
  const autoShip = useMemo(() => product.autoShip, [product.autoShip])

  return (
    <Styled.ProductListEntry>
      <Styled.GapContainer>
        <Styled.ItemDetails>
          <Link to={`/products/${product.urlKey}`}>
            <Styled.ItemImage alt={product.name} src={product.image || productImagePlaceholder} />
          </Link>
          <Styled.Container>
            <Styled.ItemProductLink urlKey={product.urlKey}>
              <Styled.ItemName>
                <Styled.ItemSku>SKU: {product.sku}</Styled.ItemSku>
                {product.name}
              </Styled.ItemName>
            </Styled.ItemProductLink>
            <Styled.ItemAttribute>Quantity: {quantity}</Styled.ItemAttribute>
            <Styled.ItemAttribute>{formatAutoShip(autoShip)}</Styled.ItemAttribute>
          </Styled.Container>
        </Styled.ItemDetails>
        <Styled.ButtonContainer>
          <FontAwesomeIcon
            className="icon"
            icon={faEdit}
            onClick={() =>
              openPrescriptionProductModal({
                urlKey: product.urlKey,
                sku: product.sku,
                mode: 'edit',
              })
            }
          />
          <FontAwesomeIcon className="icon" icon={faTrashAlt} onClick={onRemoveItem} />
        </Styled.ButtonContainer>
      </Styled.GapContainer>
    </Styled.ProductListEntry>
  )
}
