import { GlobalStyle } from '@designsforhealth/dfh-react-components'
import React from 'react'

import Head from '../../../layouts/Head'
import Layout from '../../global/Layout'
import Builder from './Builder'

const CreatePrescription = () => {
  return (
    <>
      <Head title={'Generate an eScript'} />
      <GlobalStyle includeTypography />
      <Layout fullWidth>
        <Builder />
      </Layout>
    </>
  )
}

export default CreatePrescription
