import React from 'react'

import ContactSupportMessage from './ContactSupportMessage'

const ErrorRetrievingDataMessage: React.FC = () => (
  <React.Fragment>
    An error occurred retrieving data, and we have been notified. <ContactSupportMessage />
  </React.Fragment>
)

export default ErrorRetrievingDataMessage
