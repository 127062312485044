import { EScriptData, EScriptProductData } from './types'
import { paramsTuplesToQueryString, QueryParamTuple, withoutTrailingSlash } from './utils'

export const eScript20230411Version = '2023-04-11' as const

const productExpressionFromData = (product: EScriptProductData) => {
  const { sku, quantity = 1, autoShip } = product
  const positiveQuantity = quantity > 0 ? quantity : 1
  return `(${[
    sku,
    ...(positiveQuantity !== 1 || autoShip ? [positiveQuantity] : []),
    ...(autoShip ? [autoShip] : []),
  ].join('*')})`
}

const queryParamsFromData = (data: EScriptData): string => {
  // products looks like '(SKU1*2*1m)(SKU2)'
  const products = data.products.map(productExpressionFromData).join('')

  const { eScriptCampaign, promoCode, operation } = data
  const params: QueryParamTuple[] = [
    ['v', eScript20230411Version],
    ['op', operation],
    ['promocode', promoCode],
    [`products`, products],
    ['ec', eScriptCampaign],
  ]
  return `?${paramsTuplesToQueryString(params)}`
}

export const generateEScript20230411Link = (data: EScriptData): string =>
  `${withoutTrailingSlash(data.dispensaryUrl)}/checkout${queryParamsFromData(data)}`
