import { DropdownCreatable as DfhDropdownCreatable } from '@designsforhealth/dfh-react-components'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { PropsOf } from '../../utils/types'

export type DropdownCreatableProps = Omit<PropsOf<typeof DfhDropdownCreatable>, 'control'>

export const DropdownCreatable: React.FC<DropdownCreatableProps> = (props) => {
  const { control } = useFormContext()
  return <DfhDropdownCreatable {...props} control={control} />
}
