import { FormControl, Radio } from '@material-ui/core'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import classnames from 'classnames'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import * as Styled from '../buy/cart/ItemOrderType/styled'

interface Option {
  value: number
  label: string | JSX.Element
  style?: React.CSSProperties
}

interface RadioButtonGroupProps {
  defaultValue: number
  name: string
  rules: {
    validate: (value: number) => boolean | string
    valueAsNumber: boolean
  }
  options: Option[]
}

export const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  defaultValue,
  name,
  rules,
  options,
}) => {
  const { control } = useFormContext()

  return (
    <FormControl component="fieldset">
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={name}
        render={({ onBlur, onChange, value }) => (
          <Styled.RadioGroup
            row
            value={value}
            onBlur={onBlur}
            onChange={(e) => onChange(Number(e.target.value))}
          >
            {options.map((option) => (
              <Styled.FormControlLabel
                key={option.value}
                className={classnames({ selected: value === option.value })}
                control={<Radio checkedIcon={<CheckCircleIcon />} />}
                label={option.label}
                value={option.value}
                style={option.style}
              />
            ))}
          </Styled.RadioGroup>
        )}
        rules={rules}
      />
    </FormControl>
  )
}

export default RadioButtonGroup
