import { Dropdown as DfhDropdown } from '@designsforhealth/dfh-react-components'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { PropsOf } from '../../utils/types'

export type DropdownProps = Omit<PropsOf<typeof DfhDropdown>, 'control'>

export const Dropdown: React.FC<DropdownProps> = (props) => {
  const { control } = useFormContext()
  return <DfhDropdown {...props} control={control} />
}
