import React from 'react'

import NotFoundPage from '../../components/NotFoundPage'
import PrescriptionBuilder from '../../components/prescriptions/builder'
import useSiteMetadata from '../../hooks/useSiteMetadata'

const PrescriptionBuilderPageRoute = () => {
  const { featureFlags } = useSiteMetadata() ?? {}

  if (!featureFlags?.manageEScriptBuilder) {
    return <NotFoundPage />
  }

  return <PrescriptionBuilder />
}

export default PrescriptionBuilderPageRoute
