import { SecondaryButton } from '@designsforhealth/dfh-react-components'
import styled from 'styled-components'

import { flexboxGapStyles } from '../../../lib/styles/flexbox-gap'
import Link from '../../global/Link'
import OptionalProductLink from '../../OptionalProductLink'
import { ProductListSearch as UnStyledProductListSearch } from '../../shop/account/dispensary/customize/featured-products/ProductListSearch'

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100%;
  background-color: ${({ theme }) => theme.colors.coolGray.cool200};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-width: 100%;
  // padding horizontal is 60px on desktop, 20px on mobile
  padding: 0 20px;
  @media (min-width: 768px) {
    padding: 0 60px;
  }
`

export const Content = styled.main`
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;

  // overriding general styling of paragraphs for cart page only
  p {
    font-family: ${({ theme }) => theme.fontFamilies.roboto};
  }
`

export const Button = styled(SecondaryButton)``

export const WarningButton = styled(SecondaryButton)`
  border-color: #e64b37;
  color: #e64b37;
  margin-left: auto;
  max-height: 50px;
  font-family:
    Roboto Condensed,
    serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.06em;
  text-align: center;
  text-transform: none;
  padding: 10px;

  &:hover {
    background-color: #e64b37;
    color: #ffffff;
  }
`
export const Header = styled.div`
  h3 {
    margin-top: 36px;
    margin-bottom: 36px;
  }
`

export const ItemsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  width: 100%;
`

export const GapContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 100%;
  // use negative margins to workaround lack of flexbox gap support in older browsers
  ${flexboxGapStyles('10px')};
`

export const ItemImage = styled.img`
  align-self: flex-start;
  border-radius: 4px;
  height: 115px;
  width: 115px;
  min-width: 115px;
  object-fit: contain;
`

export const SkeletonImage = styled.div`
  align-self: flex-start;
  border-radius: 4px;
  height: 115px;
  width: 115px;
  min-width: 115px;
  margin-right: 16px;
  background-color: ${({ theme }) => theme.colors.coolGray.cool100};
`

export const Container = styled.div`
  // make the text expand to fill the available space in the flexbox
  // on bigger screens don't let the text wrap
  flex: 1 1 auto;
  white-space: normal;
  @media (min-width: 768px) {
    white-space: nowrap;
  }
  text-overflow: ellipsis;
  max-width: initial;
`

export const ItemProductLink = styled(OptionalProductLink)`
  color: inherit;
  text-decoration: none;
  transition: color 0.15s;

  &:hover {
    color: ${({ theme }) => theme.colors.component.link};
  }
`

export const ItemName = styled.div`
  max-width: 320px;
  font-family: ${({ theme }) => theme.fontFamilies.roboto};
  line-height: 1.43;
`

export const ItemSku = styled.div`
  font-size: 10px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.grayscale.gray40};
`

export const ItemAttribute = styled.div`
  color: ${({ theme }) => theme.colors.grayscale.black};
  font-size: 14px;
  line-height: 1.67;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-evenly;
  @media (min-width: 445px) {
    width: unset;
    justify-content: space-between;
    flex-direction: column;
  }

  .icon {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.coolGray.cool450};

    &:hover {
      color: ${({ theme }) => theme.colors.coolGray.cool600};
    }

    width: 25px;
    height: 25px;
    padding: 8px 6px;

    @media (min-width: 445px) {
      padding: 18px 6px;
    }
  }

  .green {
    color: darkseagreen;
  }
`

export const ItemDetails = styled.div`
  display: flex;
  flex-wrap: nowrap;
  min-width: 320px;
  flex: 1 0 320px;
  align-items: center;
`

export const Instructions = styled.p`
  margin-bottom: 10px;
`

export const ProductListEntry = styled.div`
  background-color: ${({ theme }) => theme.colors.grayscale.white};
  padding: 14px;
  margin-bottom: 16px;
  border-radius: 7px;
  @media (max-width: 445px) {
    padding: 14px 2px 14px 0;
  }
`

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.colors.component.link};
`

export const Heading = styled.h2`
  margin-top: 30px;
`

export const SubHeading = styled.h3`
  margin-top: 30px;
`

export const SubHeadingContainer = styled.h3`
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ProductListSearch = styled(UnStyledProductListSearch)`
  max-width: 100%;
`

export const InfoAlert = styled.div`
  padding: 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 2px solid #61c683;
  background: var(--neutral-white-main, #fff);
  margin-top: 20px;
`

export const InfoAlertText = styled.div`
  display: flex;
  justify-content: space-between;
  color: var(--primary-black-main, #172b4d);
  font-feature-settings:
    'clig' off,
    'liga' off;
  font-family: Roboto, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
`

export const UndoLink = styled.a`
  color: #61c683;
  font-family: Roboto, serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  text-decoration: none;
  cursor: pointer;
`
