import { Grid, Typography } from '@material-ui/core'
import React, { useCallback, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'

import useSiteMetadata from '../../../hooks/useSiteMetadata'
import useSubmitFormOnDirty from '../../../hooks/useSubmitFormOnDirty'
import { OrderType } from '../../../lib/cart/item'
import { autoShipIntervalOptions } from '../../../lib/cart/item-control-options'
import { AutoShipOption } from '../../../lib/prescriptions/builder/types'
import * as Styled from '../../buy/cart/ItemOrderType/styled'
import { Dropdown as DropdownUnStyled } from '../../form/Dropdown'
import RadioButtonGroup from '../../form/RadioButtonGroup'
import { AutoShipControlLabel } from '../../global/cart/AutoShipControlLabel'

export const Dropdown = styled(DropdownUnStyled)`
  margin-top: 10px;
  margin-bottom: 12px;
  width: 175px;
`

const StyledGrid = styled(Grid)`
  flex-basis: unset;

  .MuiFormControl-root {
    width: 100%;

    label {
      flex: 1;
    }
  }
`

export interface AutoShipSelectorProps {
  autoShip: AutoShipOption
  onUpdateAutoShip: (autoShip: AutoShipOption) => void
}

type FormValues = {
  orderType: OrderType
  autoShipInterval: { value: string; label: string }
}
const mapFormValuesToAutoShipOption = ({
  orderType,
  autoShipInterval,
}: {
  orderType: OrderType
  autoShipInterval?: { value: string; label: string }
}): AutoShipOption => {
  switch (orderType) {
    case OrderType.SingleOrder:
      return null
    case OrderType.AutoShip:
      switch (autoShipInterval?.value) {
        case '1':
          return 1
        case '2':
          return 2
        case '3':
          return 3
        default:
          return 1
      }
  }
}

const AutoShipSelector: React.FC<AutoShipSelectorProps> = ({ autoShip, onUpdateAutoShip }) => {
  const defaultOrderType = useMemo(
    () => (autoShip ? OrderType.AutoShip : OrderType.SingleOrder),
    [autoShip],
  )

  const defaultAutoShipInterval = useMemo(
    () =>
      autoShipIntervalOptions.find((option) => Number(option.value) === autoShip) ??
      autoShipIntervalOptions[0],
    [autoShip],
  )

  const methods = useForm<FormValues>({
    mode: 'onChange',
    defaultValues: {
      orderType: defaultOrderType,
      autoShipInterval: defaultAutoShipInterval,
    },
  })
  const { errors, watch, handleSubmit, reset, formState } = methods
  const currentOrderType = watch('orderType', defaultOrderType)

  useSubmitFormOnDirty({
    formState,
    handleSubmit,
    reset,
    defaultValues: useMemo(
      () => ({
        orderType: defaultOrderType,
        autoShipInterval: defaultAutoShipInterval,
      }),
      [defaultAutoShipInterval, defaultOrderType],
    ),
    onSubmit: useCallback(
      (formValues: FormValues) => onUpdateAutoShip(mapFormValuesToAutoShipOption(formValues)),
      [onUpdateAutoShip],
    ),
  })

  const { featureFlags } = useSiteMetadata() ?? {}
  const showAutoShipAndSave = !!featureFlags?.autoShipAndSave

  return (
    <FormProvider {...methods}>
      <StyledGrid item xs={12}>
        <RadioButtonGroup
          defaultValue={defaultOrderType}
          name="orderType"
          rules={{
            validate: (value) => {
              switch (Number(value)) {
                case OrderType.SingleOrder:
                case OrderType.AutoShip:
                  return true
                default:
                  return 'Must select an option'
              }
            },
            valueAsNumber: true,
          }}
          options={[
            {
              value: OrderType.SingleOrder,
              label: 'Single Order',
            },
            {
              value: OrderType.AutoShip,
              label: <AutoShipControlLabel showSaveText={showAutoShipAndSave} />,
              style: {
                width: 'fit-content',
                paddingRight: '6px',
              },
            },
          ]}
        />
      </StyledGrid>
      {errors.orderType && errors.orderType.type !== 'server' && (
        <Grid item xs={12}>
          <Styled.ErrorMessage variant="body2">{errors.orderType.message}</Styled.ErrorMessage>
        </Grid>
      )}

      <Grid style={{ flexBasis: 'unset' }} item xs={12}>
        {currentOrderType !== OrderType.AutoShip ? (
          <Typography variant="caption" paragraph display="block">
            {showAutoShipAndSave
              ? 'Set up Auto-Ship & your patient will save 5% on this product. Auto-Ship can be canceled at any time.'
              : 'Set up Auto-Ship and your patient will never run out of the supplements they love! Cancel at any time.'}
          </Typography>
        ) : (
          <>
            {showAutoShipAndSave && (
              <Typography variant="caption" display="block">
                Your patient will receive a 5% discount on this product.
              </Typography>
            )}
            <Dropdown
              defaultValue={defaultAutoShipInterval}
              name="autoShipInterval"
              options={autoShipIntervalOptions}
              prompt="Ship every:"
            />
          </>
        )}
      </Grid>
    </FormProvider>
  )
}

export default AutoShipSelector
