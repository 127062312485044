import React, { useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'

import useSubmitFormOnDirty from '../../../hooks/useSubmitFormOnDirty'
import { DropdownCreatable } from '../../form/DropdownCreatable'

const StyledDropdownCreatable = styled(DropdownCreatable)`
  margin: 0;
  width: 120px;

  .react-select__control {
    height: 100%;
  }

  & > div {
    margin: 0;
  }
`

const validQuantityOptions: number[] = Array.from({ length: 100 }, (_, i) => i + 1)

const quantityOptions = validQuantityOptions.map((value) => ({
  label: String(value),
  value: String(value),
}))

const QuantitySelector: React.FC<{
  quantity: number
  onUpdateQuantity: (quantity: number) => void
}> = ({ quantity, onUpdateQuantity }) => {
  const defaultQuantityOption = useMemo(
    () => quantityOptions.find(({ value }) => Number(value) === quantity) ?? quantityOptions[0],
    [quantity],
  )

  const methods = useForm<{
    quantity: {
      label: string
      value: string
    }
  }>({
    mode: 'onChange',
    defaultValues: {
      quantity: defaultQuantityOption,
    },
  })

  const { handleSubmit, formState, reset } = methods

  useSubmitFormOnDirty({
    formState,
    handleSubmit,
    reset,
    defaultValues: useMemo(() => ({ quantity: defaultQuantityOption }), [defaultQuantityOption]),
    onSubmit: ({ quantity: { value } }) => onUpdateQuantity(Number(value)),
  })

  return (
    <FormProvider {...methods}>
      <StyledDropdownCreatable
        name={'quantity'}
        options={quantityOptions}
        defaultValue={defaultQuantityOption}
        menuPlacement="auto"
        formatCreateLabel={(inputValue) => inputValue}
        isValidNewOption={(inputValue, selectValue, selectOptions) => {
          if (selectOptions.some(({ value }) => value === inputValue)) {
            return false
          }
          const numberValue = Number(inputValue)
          return !isNaN(numberValue) && validQuantityOptions.includes(numberValue)
        }}
      />
    </FormProvider>
  )
}

export default QuantitySelector
