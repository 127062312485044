import React from 'react'

import { GetCustomerDispensaryPromosDocument } from '../../../graphql/camel'
import withDispensaryAccount, {
  WithDispensaryAccountProps,
} from '../../../hoc/withDispensaryAccount'
import useCamelQuery from '../../../hooks/useCamelQuery'
import QueryFallback from '../../shop/account/QueryFallback'
import Spinner from '../../Spinner'
import DispensaryPromoSelectorContents from './DispensaryPromoSelectorContents'

type DispensaryPromoSelectorProps = WithDispensaryAccountProps & {
  onSelectPromoCode: (promoCode: string) => void
  promoCode: string
}

const DispensaryPromoSelector: React.VFC<DispensaryPromoSelectorProps> = ({
  dispensary,
  onSelectPromoCode,
  promoCode,
}) => {
  const { data, error, loading, refetch } = useCamelQuery(GetCustomerDispensaryPromosDocument, {
    variables: {
      dispensarySlug: dispensary.slug,
    },
    // always fetch fresh dispensary promos
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
  })

  return (
    <>
      {!!error && !loading && (
        <>
          <QueryFallback
            buttonText="Retry Loading Promotions"
            error={error}
            onRetry={() => refetch()}
          />
        </>
      )}
      {!loading && data ? (
        <DispensaryPromoSelectorContents
          promoCode={promoCode}
          onSelectPromoCode={onSelectPromoCode}
          data={data}
        />
      ) : (
        <Spinner loading />
      )}
    </>
  )
}

export default withDispensaryAccount(DispensaryPromoSelector)
