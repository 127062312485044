import { differenceInDays, format, isValid, parseISO } from 'date-fns'

export function formatIfValid(date: Date, template: string): string | undefined {
  return isValid(date) ? format(date, template) : undefined
}

export const monthDayYear = (date: string | undefined | null, formatString = 'P'): string =>
  date ? formatIfValid(parseISO(date), formatString) ?? '' : ''

export const daysFromToday = (date: string | undefined | null): number | undefined =>
  date ? differenceInDays(parseISO(date), new Date()) : undefined
