import { SelectorGroup } from '@designsforhealth/dfh-react-components'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import {
  defaultPrescriptionItemFormValues,
  VariantFieldValues,
} from '../../../lib/prescriptions/builder/utils'
import { logAndCaptureException } from '../../../utils/errorTools'
import {
  AttributeSkuMapping,
  availableVariantSelections,
  FormattedVariantData,
} from '../../../utils/itemTools'

export type ItemVariantOptionsProps = {
  variantData: FormattedVariantData
  onVariantSelected: (sku: string) => void
  selectedVariantSku?: string
}

const StyledSelectorGroup = styled(SelectorGroup)`
  & > div {
    flex: 1;
    max-width: 132px;
  }
`

export const ItemVariantOptions: React.VFC<ItemVariantOptionsProps> = ({
  variantData,
  onVariantSelected,
  selectedVariantSku,
}) => {
  const { usedAttributes } = variantData

  const methods = useForm<VariantFieldValues>({
    defaultValues: defaultPrescriptionItemFormValues({
      ...variantData,
      availableVariantOptions: availableVariantSelections({ ...variantData }),
      sku: selectedVariantSku,
    }),
  })
  const { register, watch } = methods

  const variantSelections = watch(usedAttributes.map((attributes) => attributes))

  useEffect(() => {
    const selectedSku = usedAttributes.reduce(
      (check, att) => check[variantSelections[att]] as AttributeSkuMapping,
      variantData.mapping,
    ) as unknown

    if (typeof selectedSku === 'string') {
      onVariantSelected(selectedSku)
      return
    }

    logAndCaptureException(
      new Error('Nested navigation/reduction did not end up with a leaf/string'),
    )
  }, [onVariantSelected, usedAttributes, variantData.mapping, variantSelections])

  return (
    <>
      {usedAttributes?.map((attribute) => (
        <div className="item-control" key={attribute}>
          <div className="control-header robotoCondensed">{attribute}</div>
          <StyledSelectorGroup
            name={attribute}
            register={register}
            selectorOptions={variantData.attributeValues[attribute].map((value) => ({
              id: value,
              label: value,
              value,
            }))}
          />
        </div>
      ))}
    </>
  )
}
