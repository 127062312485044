import React from 'react'

import Spinner from '../../Spinner'
import * as Styled from './styled'

export const SkeletonProductListEntry: React.FC = () => {
  return (
    <Styled.ProductListEntry>
      <Styled.GapContainer>
        <Styled.ItemDetails>
          <Styled.SkeletonImage />
          <Spinner loading />
        </Styled.ItemDetails>
        <Styled.ButtonContainer>
          <div className="icon" />
        </Styled.ButtonContainer>
      </Styled.GapContainer>
    </Styled.ProductListEntry>
  )
}
